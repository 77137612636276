<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6">
        <order-add-new />
      </b-col>
      <b-col lg="6">
        <order-pie-chart />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <order-table></order-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import OrderTable from '@/layouts/components/tables/OrderTable.vue';
import OrderAddNew from '@/layouts/components/widgets/OrderAddNew.vue';
import OrderPieChart from '@/layouts/components/widgets/OrderPieChart.vue';

export default {
  components: {
    BRow,
    BCol,
    OrderTable,
    OrderAddNew,
    OrderPieChart,
  },
};
</script>

<style></style>

<template>
  <b-card
    no-body
    text-variant="white"
    bg-variant="primary"
    id="add-order-button"
    v-b-modal.modal-add-order-button
  >
    <b-card-header>
      <b-card-title title="Auftrag hinzufügen" class="text-white" />
    </b-card-header>
    <b-card-body class="d-flex justify-content-center align-items-center">
      <b-card-text class="display-1">+</b-card-text>
    </b-card-body>
    <add-order-modal uniqueName="button" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  VBModal,
} from 'bootstrap-vue';
import AddOrderModal from '@/layouts/components/modals/AddOrderModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    VBModal,
    AddOrderModal,
  },
  directives: {
    'b-modal': VBModal,
  },
};
</script>

<style scoped>
#add-order-button {
  cursor: pointer;
}
</style>
